import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  futureProofSection: {
    margin: 0,
    width: "100vw",
    border: "none",
    fontStyle: "normal",
    color: "#000",
    backgroundColor: "#F6F3F0",
    padding: "90px 10%",
  },
  "& p": {
    margin: "auto",
    width: "60vh",
    justifyContent: "center",
  },
  bullets: {
    marginTop: "30px",
    listStyle: "none",
    "& li": {
      fontSize: "14px",
      fontWeight: 300,
    },
    "& li::before": {
      color: theme.palette.secondary.main,
      content: '"\\2022"',
      fontSize: "44px",
      fontWeight: "bold",
      display: "inline-block",
      width: ".6em",
      marginLeft: "-1em",
      verticalAlign: "middle",
      lineHeight: "34px",
    },
  },
}));
