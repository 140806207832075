import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  bold: {
    fontWeight: "bold",
  },
  orange: {
    fontWeight: "bold",
    color: theme.palette.secondary.main,
  },
}));
