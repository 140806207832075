import * as React from "react";
import { useStyles } from "./GetMostOfPVSection.styles";
import { Grid, Typography } from "@material-ui/core";
import HeadingDash from "../../../../common/components/HeadingDash/HeadingDash";
import IconCard from "../../../../common/components/IconCard/IconCard";
import { IconSolar } from "../../../../common/icons/IconSolar";
import { IconData } from "../../../../common/icons/IconData";
import { IconCloud } from "../../../../common/icons/IconCloud";
import { IconAnalytics } from "../../../../common/icons/IconAnalytics";

const GetMostOfPVSection = (): JSX.Element => {
  const classes = useStyles();

  return (
    <section className={classes.futureProofSection}>
      <Grid
        container
        spacing={3}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12} lg={6}>
          <Typography variant="h2">
            <HeadingDash justify="flex-start" />
            Get the most out of your PV portfolio investment
          </Typography>
          <ul>
            <li>
              Manage and monitor solar energy sources to improve asset
              profitability
            </li>
            <li>Easily connect with all your internal & 3rd party systems</li>
            <li>Modular & flexible with top notch technologies</li>
          </ul>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Grid container spacing={2}>
            <Grid container item xs={6} md={3}>
              <IconCard icon={<IconSolar />} text="Solar Plant Data" />
            </Grid>
            <Grid container item xs={6} md={3}>
              <IconCard icon={<IconData />} text="Power Prices Data" />
            </Grid>
            <Grid container item xs={6} md={3}>
              <IconCard icon={<IconCloud />} text="Weather Forecast" />
            </Grid>
            <Grid container item xs={6} md={3}>
              <IconCard
                icon={<IconAnalytics />}
                text="Additional Data Sources"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </section>
  );
};
export default GetMostOfPVSection;
