import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    width: "100vw",
    border: "none",
    fontStyle: "normal",
    color: "#000",
    backgroundColor: "#fff",
    padding: "120px 10% 120px 10%",
    "& h4": {
      margin: "16px 0 32px 0",
    },
  },
  iconsContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "stretch",
    justifyContent: "flex-start",
    gap: "20px",
    marginTop: "-40px",
    [theme.breakpoints.up("md")]: {
      marginTop: "-50px",
      flexDirection: "column",
      alignItems: "center",
    },
  },
  iconCard: {
    width: "150px",
  },
  bulletsNoMarginTop: {
    marginTop: "0",
  },
  description: {
    marginBottom: theme.spacing(3),
  },
}));
