import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import { theme } from "../../common/styles/theme/theme.style";
import SEO from "../../EpSolutions/Solar/Seo/SEO";
import SolarEnergyPlatform from "../../EpSolutions/Solar/SolarEnergyPlatform/SolarEnergyPlatform";
import Layout from "../../common/Layout/Layout";

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <SEO />
      <CssBaseline />
      <Layout>
        <SolarEnergyPlatform />
      </Layout>
    </ThemeProvider>
  );
};

export default App;
