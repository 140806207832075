import * as React from "react";
import { useStyles } from "./ChooseServiceSection.styles";
import { Grid, Typography } from "@material-ui/core";
import HeadingDash from "../../components/HeadingDash/HeadingDash";
import { IconReports } from "../../icons/IconReports";
import { IconPowerfulAPI } from "../../icons/IconPowerfulAPI";
import { IconExport } from "../../icons/IconExport";
import IconCard from "../../components/IconCard/IconCard";

interface ChooseServiceSectionProps {
  title: string;
  description: string;
  displayMLInfo: boolean;
  features: string[];
}

const ChooseServiceSection = ({
  title,
  description,
  features,
  displayMLInfo = false,
}: ChooseServiceSectionProps): JSX.Element => {
  const classes = useStyles();

  return (
    <section className={classes.root}>
      <Grid container>
        <Grid item xs={12} md={8}>
          <Typography variant="h2">
            <HeadingDash justify="flex-start" />
            {title}
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={8}>
        <Grid item xs={12} md={8}>
          <Typography variant="h4" className={classes.description}>
            {description}
          </Typography>
          {displayMLInfo ? (
            <Typography variant="body2">
              The ML models are constantly analyzing incoming data and looking
              for abnormalities and patterns to improve the longevity and
              efficiency of the DERS.
            </Typography>
          ) : null}
          <ul className={`${displayMLInfo ? "" : classes.bulletsNoMarginTop}`}>
            {features.map((feature, i) => (
              <li key={i}>{feature}</li>
            ))}
          </ul>
        </Grid>
        <Grid item xs={12} md={4} className={classes.iconsContainer}>
          <IconCard
            icon={<IconReports />}
            text="Custom reports"
            className={classes.iconCard}
          />
          <IconCard
            icon={<IconPowerfulAPI />}
            text="Powerful API"
            className={classes.iconCard}
          />
          <IconCard
            icon={<IconExport />}
            text="Data export"
            className={classes.iconCard}
          />
        </Grid>
      </Grid>
    </section>
  );
};
export default ChooseServiceSection;
