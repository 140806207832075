import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: "100%",
      borderRadius: "15px",
      padding: `${theme.spacing(2)}px 0`,
      gap: "20px",
      "& hr": {
        width: "50%",
        height: "2px",
        backgroundColor: "#e4e4e4",
        border: "none",
        marginBottom: theme.spacing(6),
      },
    },
    title: {
      fontWeight: 600,
      textAlign: "center",
      marginBottom: theme.spacing(3),
      color: theme.palette.secondary.main,
    },
    featurePaper: {
      height: "100px",
      padding: "30px",
      marginBottom: theme.spacing(3),
    },
  })
);
