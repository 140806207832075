import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  container: {
    padding: "90px 10%",
    backgroundColor: "#F6F3F0",
  },
  title: {
    textAlign: "center",
    marginBottom: "50px",
  },
  solutions: {
    display: "flex",
    justifyContent: "space-evenly",
  },
});
