import React from "react";
import { useStyles } from "./SolutionsSection.styles";
import { Grid, Typography } from "@material-ui/core";
import HeadingDash from "../../../../common/components/HeadingDash/HeadingDash";
import Solution from "./Solution/Solution";

const platformComponentFeatures = [
  "IoT & Connectivity",
  "Rule-Based Engine",
  "Site and Client Management",
];
const useCaseFeatures = [
  "In-depth real time information about all devices and alarm notifications",
  "Automatic reporting and customization",
  "Portfolio management",
];
const benefitsFeatures = [
  "Diagnose faults & take decisions in real time",
  "Reduced field visits",
  "Reduced man hours on manual reporting",
  "Centralized monitoring for entire portfolio",
];

const SolutionsSection = (): JSX.Element => {
  const classes = useStyles();
  return (
    <section className={classes.container}>
      <HeadingDash justify="center" />
      <Typography variant="h2" className={classes.title}>
        Solutions for business
      </Typography>
      <Grid container spacing={6}>
        <Grid item xs={12} md={4}>
          <Solution
            title="Platform Component"
            features={platformComponentFeatures}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Solution title="Use Case" features={useCaseFeatures} />
        </Grid>
        <Grid item xs={12} md={4}>
          <Solution title="Benefits" features={benefitsFeatures} />
        </Grid>
      </Grid>
    </section>
  );
};

export default SolutionsSection;
