import React from "react";
import { useStyles } from "./Solution.styles";
import { Box, Typography } from "@material-ui/core";
import FeaturePaper from "../../../../../common/components/FeaturePaper/FeaturePaper";

interface Props {
  title: string;
  features: string[];
}

const Solution = ({ title, features }: Props): JSX.Element => {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <Typography variant="body1" className={classes.title}>
        {title}
      </Typography>
      <hr />
      {features.map((feature, i) => (
        <FeaturePaper key={i} className={classes.featurePaper}>
          {feature}
        </FeaturePaper>
      ))}
    </Box>
  );
};

export default Solution;
