import React from "react";
import GetMostOfPVSection from "../Sections/GetMostOfPV/GetMostOfPVSection";
import SolutionsSection from "../Sections/SolutionsSection/SolutionsSection";
import StartingSection from "../../../common/sections/StartingSection/StartingSection";
import SummarySection from "../../../common/sections/SummarySection/SummarySection";
import { useStyles } from "./SolarEnergyPlatform.styles";
import ChooseServiceSection from "../../../common/sections/ChooseServiceSection/ChooseServiceSection";
import OurEcosystemSection from "../../../common/sections/OurEcosystemSection/OurEcosystemSection";
import AllFeaturesSection from "../../../common/sections/AllFeaturesSection/AllFeaturesSection";
import { SolarEpIllustration } from "../../../common/images/illustrations/SolarEpIllustration";
import { SolarAllFlatIcon } from "../../../common/images/SolarAllFlatIcon";
import { SolarEcosystem } from "../../../common/images/SolarEcosystem";
import { EmailInput } from "../../../common/Layout/Layout";
import { EpServicesEnum } from "../../../api/useEmailService";

const ChooseServiceSectionFeatures = [
  "Works with all inverters, energy meters & weather stations",
  "Integrated forecasting module",
  "Future ready technology stack, ready to handle the data processing needs of large solar PV plants",
  "Integrated alarm & workforce management modules",
];

const AllFeaturesSectionFeatures = [
  "Plant & Portfolio Order Automation",
  "Asset & Alarm Management",
  "Task and work Order Automation",
  "Monitoring & Analytics",
  "Devices & Protocols Integrations",
  "Reporting & Forecasting",
  "Predictive Maintenance",
];

interface SolarEpProps {
  emailInputRef: EmailInput;
}

const SolarEnergyPlatform = ({ emailInputRef }: SolarEpProps): JSX.Element => {
  const classes = useStyles();
  return (
    <>
      <StartingSection
        service={EpServicesEnum.Solar}
        emailInputRef={emailInputRef}
        title="SolarEP"
        subtitle="Generate more power and optimize the performance of various solar PV assets using an all-in-one platform"
        description="SolarEP is a comprehensive solution that includes data acquisition, cloud-based monitoring, operation software, and high-level analytics for utility and rooftop solar PV assets."
        illustration={<SolarEpIllustration />}
      />
      <SummarySection>
        <>
          <b> SolarEP </b>
          is <span className={classes.orange}>
            a comprehensive solution
          </span>{" "}
          that includes data acquisition, cloud-based monitoring,
          <br />
          operation software, and high-level analytics for utility and rooftop
          solar PV assets.
        </>
      </SummarySection>
      <GetMostOfPVSection />
      <ChooseServiceSection
        title="Why choose the SolarEP platform?"
        description="SolarEP helps to optimize the performance of solar assets. We offer a comprehensive solution which includes data acquisition hardware, cloud-based monitoring software and advanced analytics for solar PV assets."
        displayMLInfo={true}
        features={ChooseServiceSectionFeatures}
      />
      <SolutionsSection />
      <OurEcosystemSection
        header="Our ecosystem"
        description="Something should be added here as text"
        picture={<SolarEcosystem />}
      />
      <AllFeaturesSection
        heading="All features in one place"
        description=""
        features={AllFeaturesSectionFeatures}
        icon={<SolarAllFlatIcon />}
      />
    </>
  );
};

export default SolarEnergyPlatform;
